.App {
  text-align: center;
  /* Carbon-recommended sans-serif font */
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
}

.content-body {
  margin: 20px
}

.about-tile-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 90%;
}

.about-tile {
  padding: 2%;
  width: 350px;
  margin: 0 auto;
}

.about-tile > img {
  width: 90%;
}

.about-tile h3 {
  font-weight:500;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.about-tile a {
  color: white;
}

.about-text {
  background-color: rgba(255,0,0,.9);
  color: white;
  border-radius: 25px;
  padding: 10px;
  font-size: 1.5em;
  line-height: 1.2;
  height: 350px;
}
/* add right padding */

.about-text ul {
  list-style-type: disc;
  text-align: left;
  margin-left: 20px;
}

.about-text li {
  margin-bottom: 10px;
}

.about-header{
  background-color: rgb(242, 242, 242);
  padding: 10px;
  border-radius: 25px;
  text-align: start;
  margin-bottom: 32px;
}

.about-header h1 {
  color: red;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
}

.about-header h3 {
  font-weight: bold;
  line-height: 1.2;
}

.about-header b {
  font-weight: bold;
}

.about-header p {
  font-size: 1.5em;
  line-height: 1.5;
}

.content-tile {
  margin: 20px 10px;
  border-radius: 25px;
  padding: 30px;
  text-align: left;
  width: 100%;
}

.content-tile a {
  color : black;
}

.content-tile h1 {
  text-align: center;
}

.content-tile h2 {
  text-align: center;
  font-weight: bold;
}

.content-tile h3 {
  text-align: start;
}

.content-tile p {
  text-align: start;
}

.content-tile b {
  font-weight: bold;
}

.section-title h2 {
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.kit-tile {
  background-color: rgb(210, 210, 210);
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 25px;
  padding: 15px;
}

.app-footer {
  display: flex;
  position: relative;
  background-color: black;
  color: white;
  font-size: 1.10em;
  min-height: 200px;
  padding-left: 10%;
  padding-right: 10%
}

.app-footer-column{
  display: flex;
  flex-direction: column;
  width:25%;
  height: 100%;
  padding: 30px 20px;
  text-align: start;
  position: relative;
  bottom:0;
}

.base-image {
  border-radius: 25px;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.app-footer-column a {
  color: white;
  text-decoration: none;
  padding: 5px 0;
}

#footer-column-header {
  font-weight: bold;
  margin-bottom: 10px;
}

#copyright {
  text-align: right;
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 100%;
  font-size: 1em;
  margin-top: 10px;
}

a.bx--tabs__nav-link {
  width: auto;
}

.mobile-header {
  display: none;
}

@media only screen
  and (min-device-width : 320px)
  and (max-device-width : 480px)
{
  .mobile-header {
    display: inline;
  }
}

